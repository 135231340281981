/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap');


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


mat-card,
h1,
h2,
h3,
h4,
h5,
p {
    padding: 0;
    margin: 0;
}


.form_sec .mat-mdc-form-field {
    width: 100% !important;
    position: relative;
    margin-bottom: 16px;
}

.form_sec mat-error {
    display: block;
    position: absolute;
    left: -223px;
    bottom: 31px;
    font-size: 14px;
    width: 181px;
    text-align: right;
    background: #edf3e5;
    padding: 3px 9px;
    border-radius: 5px;
    border: 2px solid #ff918a;
}

.form_sec mat-error:before {
    border-width: 36px;
    border-bottom: 12px solid #0000;
    border-left: 15px solid #edf3e5;
    ;
    border-top: 8px solid #0000;
    content: "";
    height: 0;
    right: -8px;
    position: absolute;
    bottom: 4px;
    transform: rotate(281deg);
    width: 0;
}

.form_sec .mat-mdc-raised-button.mat-primary {
    background: linear-gradient(180deg, rgba(75, 75, 75, 1) 0%, rgba(19, 19, 19, 1) 100%);
    width: 100%;
    border-radius: 35px;
    padding: 32px;
    margin: 19px 0;
}

.form_sec .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.form_sec .mat-mdc-text-field-wrapper {
    overflow: unset;
    --mdc-filled-text-field-container-shape: 0px;
}

.form_sec .mat-mdc-form-field-infix {
    min-height: 40px;
    padding: 8px 0 !important;
}

.form_sec .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 20px;
}

.form_sec .mdc-button .mdc-button__label {
    position: relative;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

.form_sec .mdc-floating-label--float-above mat-label {
    display: none;
}

.form_sec .mdc-floating-label--float-above .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
    display: none;
}

@media (max-width:1199px) {
    .form_sec mat-error {
        display: block;
        position: absolute;
        left: -12px;
        bottom: -20px;
        font-size: 13px;
        width: auto;
        text-align: left;
        background: transparent;
        padding: 0;
        border-radius: 0;
        border: none;
    }

    .form_sec mat-error:before {
        display: none;
    }

    .form_sec .mat-mdc-form-field {
        margin-bottom: 20px;
    }
}

/* -----------------------------nda_contract_form---------------------------------- */

.nda_full_width .mat-mdc-form-field-infix {
    min-height: 40px;
    padding: 8px 0 !important;
}

.nda_full_width .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

/* .nda_full_width .mdc-text-field--filled:not(.mdc-text-field--disabled){
 background: #fff !important;
} */
.nda_full_width .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important; padding: 0 5px;
}

.date .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.whole_wrpr .mdc-line-ripple--active {
    background-color: #fff !important;
}

.whole_wrpr .mdc-line-ripple--active {
    background: #fff !important;
}

.whole_wrpr .mdc-text-field {
    overflow: unset;
}



/* ------------------------NDA form css--------------- */
.common_line {
    display: inline-block;
    border-bottom: 1px solid #000;
    height: 30px;
    padding-bottom: 10px;
    margin: 0 10px;
    text-align: center;
    font-weight: bold;
}

.name_line {
    width: 50%;
}

.address_line {
    width: 300px;
}
.address_line {width: 55%;}
.state_line {width: 35%;}
.city_line, .zip_line {width: 20%;}